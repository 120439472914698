import { useState, useEffect } from 'react'
import Keycloak, { KeycloakProfile, KeycloakInstance } from 'keycloak-js'

const getToken = (keycloak: KeycloakInstance) => () =>
    new Promise((resolve, reject) =>
        keycloak
            .updateToken(20)
            .success(() => resolve(keycloak.token))
            .error(() => reject('Failed to refresh token'))
    )

type Auth = {
    authenticated: boolean
    token?: Function
}

export function useAuth() {
    const [auth, setAuth] = useState<Auth>({ authenticated: false })
    useEffect(() => {
        const keycloak = Keycloak({
            url: 'https://accounts.heig-vd.ch/auth',
            realm: 'HEIG-VD',
            clientId: 'janitor',
        })
        keycloak
            .init({ onLoad: 'login-required', checkLoginIframe: false })
            .success((authenticated: boolean) => {
                setAuth({
                    authenticated,
                    token: getToken(keycloak),
                })
            })
            .error(console.error)
    }, [])

    return auth
}

export default useAuth
