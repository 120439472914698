import * as React from 'react'
import nodes from '../intra.json'
import { Input, PseudoBox, Box, Stack, Checkbox } from '@chakra-ui/core'
import { useDebouncedCallback } from 'use-debounce'
import Pusher from 'pusher-js'
import useAuth from './Auth'
import { v4 } from 'uuid'

type Node = {
    Url: string
    Title: string
    Children: Node[]
    Depth: number
}

const pusher = new Pusher('c4f683babde661b2491f', {
    cluster: 'eu',
})

const instanceId = v4()

const flatten = (nodes: Node[], res: Node[] = [], depth = 1) =>
    nodes.reduce((acc, node) => {
        const { Children: children, ...rest } = node
        res.push({ ...rest, Children: [], Depth: depth })
        if (children.length > 0) {
            flatten(children, acc, depth + 1)
        }
        return acc
    }, res)

const getId = (node: Node) => `${node.Title}*${node.Url}`

type Phases = {
    migration?: boolean
    phase1?: boolean
    phase2?: boolean
}
type State = Record<string, Phases>

const defaultState: State = {}

const Item: React.FunctionComponent<{
    state: State
    node: Node
    id: string
    onChange: Function
}> = ({ state, node, id, onChange }) => {
    return (
        <>
            <PseudoBox _hover={{ bg: 'blue.50' }}>
                <Stack isInline minW={700}>
                    <Box>
                        <a
                            href={`https://new-intra2.heig-vd.ch${node.Url}`}
                            target="_blank"
                        >
                            {node.Title}
                        </a>
                    </Box>
                    <Box
                        display="flex"
                        flex={1}
                        minW={300}
                        justifyContent="flex-end"
                        flexDirection="row"
                    >
                        <Stack spacing={4} isInline>
                            <Checkbox
                                onChange={(e) =>
                                    onChange(
                                        'migration',
                                        node,
                                        e.target.checked
                                    )
                                }
                                isChecked={state[id]?.migration}
                            >
                                Migration
                            </Checkbox>
                            <Checkbox
                                onChange={(e) =>
                                    onChange('phase1', node, e.target.checked)
                                }
                                isChecked={state[id]?.phase1}
                            >
                                Phase I
                            </Checkbox>
                            <Checkbox
                                onChange={(e) =>
                                    onChange('phase2', node, e.target.checked)
                                }
                                isChecked={state[id]?.phase2}
                            >
                                Phase II
                            </Checkbox>
                        </Stack>
                    </Box>
                </Stack>
            </PseudoBox>
            <Box color="gray.500" fontSize="xs" as="a">
                {node.Url}
            </Box>
        </>
    )
}

const List: React.FunctionComponent<{
    node: Node
    depth?: number
    state: State
    onChange: Function
}> = ({ node, depth = 1, state, onChange }) => {
    return (
        <Box as="ul" pl={4}>
            {node.Children.map((x) => {
                const id = getId(x)
                return (
                    <Box as="li" key={id} mt="1rem">
                        <Item
                            state={state}
                            onChange={onChange}
                            id={id}
                            node={x}
                        />

                        <Box color="red.500" fontSize="xs">
                            level {depth}
                        </Box>
                        <List
                            node={x}
                            depth={depth + 1}
                            state={state}
                            onChange={onChange}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}

const SearchList: React.FunctionComponent<{
    nodes: Node[]
    search: string
    state: State
    onChange: Function
}> = ({ nodes, search, onChange, state }) => (
    <Box as="ul" pl={4}>
        {nodes
            .filter((x) => new RegExp(search, 'i').test(x.Title))
            .map((x) => {
                const id = getId(x)
                return (
                    <Box as="li" key={id} mt="1rem">
                        <Item
                            onChange={onChange}
                            state={state}
                            id={id}
                            node={x}
                        />
                    </Box>
                )
            })}
    </Box>
)

const getState = (
    token: () => Promise<string>,
    setState: React.Dispatch<React.SetStateAction<Record<string, Phases>>>
) =>
    token().then((bearer) => {
        fetch('/api/state', {
            headers: {
                authorization: `Bearer ${bearer}`,
            },
        })
            .then((x) => x.json())
            .then((x) => {
                setState(x)
            })
    })

const App: React.FunctionComponent<{
    token: () => Promise<string>
}> = ({ token }) => {
    const [state, setState] = React.useState(defaultState)
    const [flatNodes, setFlatNodes] = React.useState<Node[]>([])
    const [searching, setSearching] = React.useState(false)
    const [search, setSearch] = React.useState('')

    React.useEffect(() => {
        getState(token, setState)

        const channel = pusher.subscribe('updates')
        channel.bind('update', function (data: unknown) {
            if (data.instanceId !== instanceId) {
                getState(token, setState)
            }
        })

        setFlatNodes(flatten(nodes))
    }, [])
    const onChange = async (name: string, node: Node, value: boolean) => {
        const id = getId(node)
        const newState = {
            ...state,
            [id]: { ...state[id], [name]: value },
        }

        setState(newState)

        const bearer = await token()

        fetch('/api/state', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
            },
            body: JSON.stringify({
                id: encodeURIComponent(id),
                name,
                value,
                instanceId,
            }),
        })
    }

    const [searchCallback] = useDebouncedCallback((value: string) => {
        setSearch(value)
        setSearching(!!value)
    }, 500)

    return (
        <Box maxW={1000} mx="auto" my="6rem" p={2}>
            <Box as="h1" fontSize="3rem" mb="6rem">
                Janitor 🧹
            </Box>
            <Input
                mb="4rem"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    searchCallback(e.target.value)
                }
            />
            {searching ? (
                <SearchList
                    nodes={flatNodes}
                    search={search}
                    state={state}
                    onChange={onChange}
                />
            ) : (
                <List
                    node={(nodes[0] as unknown) as Node}
                    state={state}
                    onChange={onChange}
                />
            )}
        </Box>
    )
}

export default () => {
    const { authenticated, token } = useAuth()
    return authenticated && token ? <App token={token} /> : null
}
